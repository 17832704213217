import * as React from "react"

import Navigation from "./navigation"
import FranklinLogo from "../assets/franklin-logo.svg"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ navigation, languageSwitcher, homeURL }) => (
  <header className="site-header">
    <div className="grid grid__container">
      <div className="grid__cell grid__cell--3@medium">
        <a
          href={homeURL}
          onClick={() => {
            if (typeof window !== "undefined") {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: "linkOrButtonClick",
                link_url: `${homeURL}`,
                link_text: `Logo click`,
                location: "",
              })
            }
          }}
        >
          {process.env.GATSBY_REGION != "zh-hk" && (
            <div className="site-logo">
              <StaticImage
                placeholder="none"
                src="../images/franklin-logo.png"
                alt=""
              />
            </div>
          )}

          {process.env.GATSBY_REGION == "zh-hk" && (
            <div className="site-logo site-logo--hk">
              <StaticImage
                placeholder="none"
                src="../images/franklin-logo-hk.png"
                alt=""
              />
            </div>
          )}
        </a>
      </div>
      <div className="grid__cell grid__cell--9@medium">
        <Navigation
          navigation={navigation}
          languageSwitcher={languageSwitcher}
        />
      </div>
    </div>
  </header>
)

export default Header
