import React, { useState } from "react"
import Burger from "../assets/burger.svg"
import Close from "../assets/close.svg"

function Navigation({ navigation, languageSwitcher }) {
  const [visibilityClass, setVisibilityClass] = useState("is-hidden")
  const [burgerVisibilityClass, setBurgerVisibilityClass] =
    useState("is-visible")

  const toggleVisibilityClass = () => {
    const newValue =
      visibilityClass === "is-hidden" ? "is-visible" : "is-hidden"
    const newBurgerValue =
      burgerVisibilityClass === "is-visible" ? "is-hidden" : "is-visible"
    setVisibilityClass(newValue)
    setBurgerVisibilityClass(newBurgerValue)
  }

  const createGTMtrackingDescription = () => {
    return visibilityClass === "is-hidden"
      ? "Burger menu opened"
      : "Burger menu closed"
  }

  return (
    <>
      <div className={`menu-trigger ${burgerVisibilityClass}`}>
        <button
          className="menu-trigger__button"
          onClick={() => {
            toggleVisibilityClass();
            const burgerState = createGTMtrackingDescription();
            if (typeof window !== "undefined") {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: "header_navigation",
                link_url: `#`,
                link_text: `${burgerState}`,
              })
            }
          }}
        >
          <span className="menu-trigger__open">
            <Burger />
          </span>

          <span className="menu-trigger__close">
            <Close />
          </span>
          <span className="screen-reader-text">Menu</span>
        </button>
      </div>

      <div className={`navigation-outer ${visibilityClass}`}>
        {navigation && (
          <nav>
            <ul className="site-navigation">
              {navigation?.map((item, index) => (
                <li className="site-navigation__item" key={index}>
                  <a
                    className="site-navigation__link"
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      if (typeof window !== "undefined") {
                        window.dataLayer = window.dataLayer || []
                        window.dataLayer.push({
                          event: "header_navigation",
                          link_url: `${item.link}`,
                          link_text: `${item.name}`,
                        })
                      }
                    }}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        )}

        {languageSwitcher && (
          <ul className="language-switcher">
            {languageSwitcher?.map((item, index) => (
              <li className="language-switcher__item" key={index}>
                <a
                  className="language-switcher__link"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (typeof window !== "undefined") {
                      window.dataLayer = window.dataLayer || []
                      window.dataLayer.push({
                        event: "header_navigation",
                        link_url: `${item.link}`,
                        link_text: `${item.name}`,
                      })
                    }
                  }}
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}

export default Navigation
