import React from "react"
import Growth from "../../assets/growth.svg"
import Growing from "../../assets/growing.svg"
import Valuation from "../../assets/valuation.svg"
import FinancialSecurity from "../../assets/financial-security.svg"
import People from "../../assets/people.svg"
import Bag from "../../assets/bag.svg"
import Chip from "../../assets/chip.svg"
import Screen from "../../assets/screen.svg"

import Facebook from "../../assets/facebook.svg"
import Linkedin from "../../assets/linkedin.svg"
import Youtube from "../../assets/youtube.svg"
import Twitter from "../../assets/twitter.svg"
import Instagram from "../../assets/instagram.svg"

import ArrowLeft from "../../assets/arrow-left.svg"
import ArrowRight from "../../assets/arrow-right.svg"

const Icon = ({ icon = null }) => {
  if (icon === "growth") {
    return <Growth />
  }

  if (icon === "growing") {
    return <Growing />
  }

  if (icon === "valuation") {
    return <Valuation />
  }

  if (icon === "financial-security") {
    return <FinancialSecurity />
  }

  if (icon === "people") {
    return <People />
  }

  if (icon === "bag") {
    return <Bag />
  }

  if (icon === "chip") {
    return <Chip />
  }

    if (icon === "facebook") {
    return <Facebook />
  }

    if (icon === "twitter") {
    return <Twitter />
  }

    if (icon === "instagram") {
    return <Instagram />
  }

    if (icon === "linkedin") {
    return <Linkedin />
  }

    if (icon === "youtube") {
    return <Youtube />
  }

  if (icon === "screen") {
    return <Screen />
  }

  if (icon === "arrow-left") {
    return <ArrowLeft />
  }

  if (icon === "arrow-right") {
    return <ArrowRight />
  }

  return null
}

export default Icon
