import * as React from "react"
import FooterMenu from "./footer-menu"
import SocialMenu from "./social-menu"
//import LanguageDropdown from "./blocks/languge-dropdown"

const Footer = ({ content = null, footerMenuItems = null, socialMenu = null }) => {

  if (!content) {
    return null
  }

  return (
    <footer className="site-footer">
      <div className="u-background-grey u-padding-small">
        <div className="grid grid__container">
          <div className="grid__cell grid__cell--8@medium">
            <p className="u-no-margin">{content.note}</p>
          </div>
          <div className="grid__cell grid__cell--4@medium">
            {/* <LanguageDropdown /> */}
          </div>
        </div>
      </div>

      <div className="u-background-white u-padding-small">
        <div className="grid grid__container">
          <div className="grid__cell u-flex">
            <FooterMenu footerMenuItems={footerMenuItems} />
            <SocialMenu items={socialMenu} />
          </div>
        </div>
      </div>

      <div className="u-background-blue u-padding-small">
        <div className="grid grid__container">
          <div className="grid__cell">
            <p className="u-no-margin u-text-smaller u-text-white">{content.copyright}</p>
          </div>
        </div>
      </div>

      {content.disclosure && (
      <div className="u-background-black u-padding-small">
        <div className="grid grid__container">
          <div className="grid__cell">
             <p className="u-no-margin u-text-smaller u-text-white" dangerouslySetInnerHTML={{ __html: content?.disclosure.split('\n').join('<br>')}} />
          </div>
        </div>
      </div>
      )}

    </footer>
  )
}

export default Footer
