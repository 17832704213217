/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/index.scss";

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, navigation, languageSwitcher, homeURL }) => {


  return (
    <>
      <Header homeURL={homeURL} navigation={navigation} languageSwitcher={languageSwitcher} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
