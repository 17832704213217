import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Icon from "./blocks/icon"

function SocialMenu({ items = null }) {
  if (items == null) {
    return null
  }

  return (
    <>
      <div className="social-menu__outer">
        <span>Connect with us: </span>
        <ul className="social-menu">
          {items?.map((item, index) => (
            <li className="social-menu__item" key={index}>
              <a
                className="social-menu__link"
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  if (typeof window !== "undefined") {
                    window.dataLayer.push({
                      event: "footer_navigation",
                      link_url: `${item.link}`,
                      link_text: `${item.name}`,
                    })
                  }
                }}
              >
                <Icon icon={item.name} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default SocialMenu
