import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function FooterMenu({ footerMenuItems = null }) {
  return (
    <>
      <ul className="footer-menu">
        {footerMenuItems?.map((item, index) => (
          <li className="footer-menu__item" key={index}>
            <a
              className="footer-menu__link"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: "footer_navigation",
                    link_url: `${item.link}`,
                    link_text: `${item.name}`,
                  })
                }
              }}
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}

export default FooterMenu
